/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Form, Nav, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../components/LanguageProvider";
import { NavLink } from "react-router-dom";
import { PropagateLoader } from 'react-spinners';
import { Distance , DownloadModal, generateExcelFile, generatePDFFile, handleDownloadConfirm, toTimestamp, useClipboard } from "../utilities/functions";
import VehicleModal from "../components/Vehicle/VehicleModal";
import { toast } from "react-toastify";
const backendUrl = process.env.REACT_APP_BACKEND_URL;


interface Vehicle {
  id_vehicule: number;
  immatriculation_vehicule: string;
  category_vehicule: string;
  vehicule_type: string;
  couleur_vehicule: string;
  id_dispositif: number;
  PSN: string;
  nom_groupe: string | null;
  preusername_user: string;
  username_user: string;
  color_groupe: string | null;
  IB_CODE: string;
  GPSDIST: number;
  nom_conducteur: string | null;
  prenom_conducteur: string | null;
  date_creation_vehicule: string;
}

export function Vehicles() {

  const id_user = localStorage.getItem("userID");
  const { translate } = useTranslate();
  const [list_vehicle, setVehicles] = useState<Vehicle[]>([]);
  const [pageCount, setPageCount] = useState(0);
  let [total, setTotal] = useState(0);
  let [limitValue, setSelectedLimitValue] = useState(10);
  const [loading, setLoading] = useState(true);
  const [modalStatus, setModalStatus] = useState<string | null>(null);
  const [titleStatus, setTitleStatus] = useState<string | null>(null);
  const [IdUser, setIdUser] = useState<number>(0);
  const [IdVehicule, setIdVehicule] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [colum, setSortColum] = useState("id_vehicule");
  const [sort, setSort] = useState("ASC");
  const [search, setSearch] = useState("");
  const [type, setType] = useState(0);
  const [typeSearch, setTypeSearch] = useState("ID Vehicle");
  const [selectedSearch, setSelectedSearch] = useState(translate("ID Vehicle"));
  const [showDownloadModal, setShowDownloadModal] = useState(false); 
  const { copyToClipboard, copiedId } = useClipboard(translate("Matriculation Copied"));
  const [selectedVehicles, setSelectedVehicles] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);



  const [selectedColumns, setSelectedColumns] = useState({
    id_vehicule: true,
    immatriculation_vehicule: true,
    category_vehicule: true,
    vehicule_type: true,
    couleur_vehicule: true,
    id_dispositif: true,
    PSN: true,
    nom_groupe: true,
    preusername_user: true,
    username_user: true,
    color_groupe: true,
    IB_CODE: true,
    GPSDIST: true,
    nom_conducteur: true,
    prenom_conducteur: true
  });



  const vehicleHeaders = [
    translate("ID"),
    translate("Matriculation"),
    translate("PSN"),
    translate("Creation date"),
    translate("Type"),
    translate("Group"),
    translate("User"),
    translate("Odometer")
    
  ];

  const vehicleData = list_vehicle.map(vehicle=> [
    vehicle.id_vehicule,
    vehicle.immatriculation_vehicule,
    vehicle.PSN,
    toTimestamp(vehicle.date_creation_vehicule),
    vehicle.vehicule_type,
    vehicle.nom_groupe,
    vehicle.username_user,
    vehicle.GPSDIST,
  ]);
  
  const downloadVehicleExcel = () => {
    generateExcelFile(translate("List")+' '+translate("Vehicles"), vehicleHeaders, vehicleData);
  };

  const downloadVehiclePDF = () => {
    generatePDFFile(translate("List")+' '+translate("Vehicles"), vehicleHeaders, vehicleData);
  };


  const onDownloadConfirm = (format: string) => {
    if (selectedVehicles.length > 0) {
      handleDownloadConfirm(format, downloadVehicleExcel, downloadVehiclePDF);
    } else {
      toast.warn("Veuillez sélectionner au moins un véhicule", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const handleTypeSearch = (event: any) => {
    const selectedValue = event.target.textContent;

    switch (selectedValue) {
      case translate("ID Véhicule"):
        setSelectedSearch(translate("ID Véhicule"))
        setType(0);
        break;
      case translate("Matriculation"):
        setSelectedSearch(translate("Matriculation"))
        setType(1);
        break;
      case translate("Type"):
        setSelectedSearch(translate("Type"))
        setType(2);
        break;
      case translate("PSN"):
        setSelectedSearch(translate("PSN"))
        setType(3);
        break;
      case translate("User"):
        setSelectedSearch(translate("User"))
        setType(4);
        break;
      case translate("IButton"):
        setSelectedSearch(translate("IButton"))
        setType(5);
        break;
      case translate("Driver"):
        setSelectedSearch(translate("Driver"))
        setType(6);
        break;
      default:
        console.log("Unknown selection");
        break;
    }

    setTypeSearch(translate(selectedValue));
    console.log("Selected value:", selectedValue);
  };

  const Options = [
    "ID Véhicule",
    "Type",
    "Matriculation",
    "PSN",
    "Driver",
    "User",
    "Group",
    "IButton"
  ];

  const handleAdvancedSearch = async (event: any) => {
    const newValue = event.target.value;
    setSearch(newValue);
    await getVehicles(limitValue, currentPage, newValue, type, colum, sort);
  };

  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleSortingColum = (currentColumn: string) => {

    sort === "DESC" ? setSort("ASC") : setSort("DESC");
    setSortColum(currentColumn);
    setCurrentPage(1)
    getVehicles(limitValue, 1, search, type, colum, sort);
  };

  const handleDeleteVehicle = async (id_vehicule: number, IdUser: number) => {
    try {
      console.log(id_vehicule);
      setModalStatus('Do you want to delete this vehicle');
      setTitleStatus('Delete vehicule');
      setIdUser(IdUser);
      setIdVehicule(id_vehicule);

      // Perform deletion logic here...

      // After successful deletion, update the vehicle list
      await updateVehicleList();
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setModalStatus(null);
  };


  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setCurrentPage(1); 
    setSelectedLimitValue(newValue);
    const commentsFormServer = await getVehicles(
      parseInt(newValue),
      1,
      search,
      type,
      colum,
      sort
    ); 
    setVehicles(commentsFormServer);
    window.scrollTo(0, 0);
  };

  useEffect(() => {

    getVehicles(limitValue, currentPage, search, type, colum, sort);

  }, []);


  const updateVehicleList = async () => {
    try {
      setLoading(true);
      const total_pages = await fetch(
        `${backendUrl}/api/vehicle/totalpage/${id_user}`,
        { mode: 'cors' }
      );
      const totalPages = await total_pages.json();
      total = totalPages[0]["total"];
      setTotal(totalPages[0]["total"]);

      const res = await fetch(
        `${backendUrl}/api/vehicle/1/${limitValue}/${id_user}`,
        { mode: 'cors' }
      );

      const data = await res.json();
      setPageCount(Math.ceil(total / limitValue));
      setVehicles(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    await getVehicles(limitValue, parseInt(currentPage), search, type, colum, sort);
    setCurrentPage(currentPage)
    window.scrollTo(0, 0)
  };

  useEffect(() => {
    if (modalStatus === 'success') {
      const timeoutId = setTimeout(() => {
        setModalStatus(null);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [modalStatus]);

  const getVehicles = async (
    limitValue: number,
    currentPage: number,
    search: string,
    type: number,
    colum: string,
    sort: string) => {

    const bodyData = JSON.stringify({
      limitValue: limitValue,
      currentPage: currentPage,
      search: search,
      type: type,
      id_user: id_user,
      colum: colum,
      sort: sort,
    });

    try {
      setLoading(true);

      const total_pages = await fetch(`${backendUrl}/api/vehicle/search/totalpage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });


      const totalPages = await total_pages.json();
      total = totalPages[0]["total"];
      setTotal(totalPages[0]["total"]);

      const vehicleResponse = await fetch(`${backendUrl}/api/vehicle/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      if (!vehicleResponse.ok) {
        console.error("Erreur lors de la récupération de la  liste vehicles");
        setLoading(false);
        return;
      }


      const data = await vehicleResponse.json();
      setPageCount(Math.ceil(total / limitValue));
      setVehicles(data);
      return data;
    } catch (error) {
      console.error(error);

    } finally {
      setLoading(false);
    }
  };

  const [isVehiclesSelected, setIsVehiclesSelected] = useState(false);


   // Dans la fonction handleDriverSelect
   const handleVehiclesSelect = (VehicleID: string) => {
    let updatedSelectedVehicles: string[] = [];
  
    // Si "Sélectionner tous les conducteurs" est activée, sélectionne ou désélectionne tous les conducteurs
    if (selectAll) {
      updatedSelectedVehicles = selectedVehicles.includes(VehicleID)
        ? selectedVehicles.filter(id => id !== VehicleID)
        : list_vehicle.map(vehicle => vehicle.id_vehicule.toString());
    } else {
      // Sinon, gère la sélection/désélection normale des conducteurs individuels
      if (selectedVehicles.includes(VehicleID)) {
        updatedSelectedVehicles = selectedVehicles.filter(id => id !== VehicleID);
      } else {
        updatedSelectedVehicles = [...selectedVehicles, VehicleID];
      }
    }
  
    // Met à jour la liste des conducteurs sélectionnés
    setSelectedVehicles(updatedSelectedVehicles);
  
    // Met à jour l'état isDriverSelected
    setIsVehiclesSelected(updatedSelectedVehicles.length > 0 || !selectAll); // Mettre à jour en fonction de la sélection de tous les conducteurs
  
    // Met à jour le conducteur sélectionné uniquement si un seul conducteur est sélectionné
    if (updatedSelectedVehicles.length === 1 && !selectAll) {
      setSelectedVehicles([VehicleID]); // Keep as an array
    } else {
      setSelectedVehicles([]); // Reset to an empty array instead of null
    }
  };
  
  
  // Si vous souhaitez désélectionner la case à cocher globale lorsque toutes les cases individuelles sont désélectionnées.
  useEffect(() => {
    if (selectedVehicles.length === 0) {
      setSelectAll(false);
    }
  }, [selectedVehicles]);

   // Dans la fonction handleSelectAllDrivers
   const handleSelectAllVehicles = (checked: boolean) => {
    setSelectAll(checked);
    if (checked) {
      const allVehiculeIDs = list_vehicle.map((vehicle) => vehicle.id_vehicule.toString()); // Convert to strings
      setSelectedVehicles(allVehiculeIDs);
    } else {
      setSelectedVehicles([]);
    }
    // Met à jour l'état isDriverSelected
    setIsVehiclesSelected(checked || selectedVehicles.length > 0);
  };


  return (
    <>
      <div className="row">
        <h4 className="col-sm-6 col-md-6">
          <i className="las la-car" data-rel="bootstrap-tooltip" title="Increased"></i>{translate('Vehicles')} ({total})
        </h4>
        <div className="col-sm-6 col-md-6 " >
          <div id="DataTables_Table_0_filter" className="float-right dataTables_filter mr-3">
            <NavLink to="/Vehicle/add" className="btn btn-primary  mr-1">
              <i className="las la-plus mr-3"></i>
              {translate('New vehicle')}
            </NavLink >
 
            {/* <a href="#" className="btn btn-outline-secondary  mr-1">
              <i className="las la-plus mr-3"></i>
              {translate('Import vehicles')}
            </a> */}
       
            <button
            className="btn btn-outline-secondary mr-1"
            onClick={() => setShowDownloadModal(true)}
          >
            <i className="las la-download"></i>
            {translate("Export")} {translate("Vehicles")}
          </button>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="row">
            <div
              className="col-md-4"
              style={{ margin: "0px 0px 10px 0px", padding: "10px" }}
            >
              <div className="input-group">
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    <i
                      className="fas fa-chevron-down"
                      style={{ fontSize: "20" }}
                    ></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu onClick={handleTypeSearch}>
                    {Options.map(option => (
                      <Dropdown.Item
                        key={option}
                        className={`${selectedSearch == translate(option) ? 'active' : ''}`}
                        onClick={() => setSelectedSearch(translate(option))}
                      >
                        {translate(option)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <input
                  type="text"
                  placeholder={` By ${typeSearch}`}
                  onChange={handleAdvancedSearch}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-8 d-flex justify-content-end align-items-center">
              <div className="dataTables_length" id="DataTables_Table_0_length">
                <label style={{ marginBottom: "0" }}>
                  {translate("Show")}
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="custom-select custom-select-sm form-control form-control-sm ml-2"
                    style={{ width: "66px" }}
                    onChange={handleSelectChange}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </label>
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  title={translate("Display columns")}
                >
                  <i className="las la-eye"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as="button"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedColumns.id_vehicule}
                      onChange={() => handleColumnChange("id_vehicule")}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      {translate("ID")}
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedColumns.immatriculation_vehicule}
                      onChange={() => handleColumnChange("immatriculation_vehicule")}
                    />
                    <span style={{ marginLeft: "10px" }}>{translate("Matriculation")}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedColumns.PSN}
                      onChange={() => handleColumnChange("PSN")}
                    />
                    <span style={{ marginLeft: "10px" }}>{translate("PSN")}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedColumns.nom_conducteur}
                      onChange={() => handleColumnChange("nom_conducteur")}
                    />
                    <span style={{ marginLeft: "10px" }}>{translate("Driver")}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedColumns.username_user}
                      onChange={() => handleColumnChange("username_user")}
                    />
                    <span style={{ marginLeft: "10px" }}>{translate("User")}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedColumns.nom_groupe}
                      onChange={() => handleColumnChange("nom_groupe")}
                    />
                    <span style={{ marginLeft: "10px" }}>{translate("Group")}</span>
                  </Dropdown.Item>

                  <Dropdown.Item
                    as="button"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedColumns.IB_CODE}
                      onChange={() => handleColumnChange("IB_CODE")}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      {translate("IButton")}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="dataTable">
              <thead className="bg-white text-uppercase">
                <tr className="ligth ligth-data">
                <th>
                  <div className="checkbox d-inline-block">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAllVehicles(e.target.checked)}
                    />
                    <label className="mb-0"></label>
                  </div>
                </th>
                  {selectedColumns.id_vehicule && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("id_vehicule")}
                    >
                      {translate("ID vehicle")}
                    </th>
                  )}
                  {selectedColumns.vehicule_type && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("vehicule_type")}
                    >
                      {translate("Type")}
                    </th>
                  )}
                  {selectedColumns.immatriculation_vehicule && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("immatriculation_vehicule")}
                    >
                      {translate("Matriculation")}
                    </th>
                  )}
                  {selectedColumns.PSN && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("PSN")}
                    >
                      {translate("PSN")}
                    </th>
                  )}
{/* 
                  {selectedColumns.prenom_conducteur && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("prenom_conducteur")}
                    >
                      {translate("Driver")}
                    </th>
                  )} */}
                  {selectedColumns.username_user && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("username_user")}
                    >
                      {translate("User")}
                    </th>
                  )}
                  {selectedColumns.nom_groupe && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("nom_groupe")}
                    >
                      {translate("Group")}
                    </th>
                  )}

                  {/* {selectedColumns.IB_CODE && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("IB_CODE")}
                    >
                      {translate("IButton")}
                    </th>
                  )} */}
                  {selectedColumns.GPSDIST && (
                    <th
                      className="sorting"
                      onClick={() => handleSortingColum("GPSDIST")}
                    >
                      {translate("Odometer")}
                    </th>
                  )}

                  <th>{translate('Actions')}</th>
                </tr>
              </thead>
              <tbody key="#" className="ligth-body">
                {loading ? (
                  <tr style={{ textAlign: "center" }}>
                    <td className="text-center" colSpan={10}>
                      <p>
                        <PropagateLoader color={"#123abc"} loading={loading} size={20} />
                      </p>
                    </td>
                  </tr>

                ) : list_vehicle.length > 0 ? (
                  list_vehicle.map((item) => (

                    <tr className={item.id_vehicule.toString()}>
                      <td>
                        <div className="checkbox d-inline-block">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`checkbox-${item["id_vehicule"]}`}
                          checked={selectedVehicles.includes(item["id_vehicule"].toString())}
                          onChange={() => handleVehiclesSelect(item["id_vehicule"].toString())}
                          />
                        <label htmlFor={`checkbox-${item["id_vehicule"]}`} className="mb-0"></label>
                      </div>
                      </td>
                      {selectedColumns.id_vehicule && <td>{item.id_vehicule}</td>}
                      {selectedColumns.vehicule_type && (
                        <td>
                          <div className="align-items-center">
                            <span className="icon icon-Aucun" style={{ background: item.couleur_vehicule }}></span>
                            <div>
                              {item.vehicule_type}
                              <p className="mb-0">
                                <small>{item.category_vehicule}</small>
                              </p>
                            </div>
                          </div>
                        </td>
                      )}
                      {selectedColumns.immatriculation_vehicule && <td
                         id={`vehicle-${item.id_vehicule}`}
                       style={{ cursor: 'pointer', position: 'relative', color: copiedId === item.id_vehicule.toString()? '#28a745' : '#007bff' }}
                       title={translate("Click to copy the registration matriculation")}
                        onClick={() => {
                          if (item.immatriculation_vehicule && item.id_vehicule.toString()) {
                            copyToClipboard(item.immatriculation_vehicule, item.id_vehicule.toString());
                          }
                        }} >{ 
                          <>
                          <span style={{ color: copiedId ===  item.id_vehicule.toString() ? '#28a745' : '#007bff' }}>
                            {item.immatriculation_vehicule}
                          </span>

                          {copiedId === item.id_vehicule.toString()  && (
                            <span
                              style={{
                                position: 'absolute',
                                top: '-20px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                backgroundColor: '#28a745',
                                color: '#fff',
                                padding: '2px 5px',
                                borderRadius: '4px',
                                fontSize: '12px',
                              }}
                            >
                              {translate("Matriculation Copied")}
                            </span>
                          )}
                        </>    
                        }   
                      </td>}
                      {selectedColumns.PSN && <td>{item.PSN}</td>}
                      {/* {selectedColumns.nom_conducteur && (
                        <td id="6">
                          {item.nom_conducteur !== "none"
                            ? `${item.nom_conducteur} ${item.prenom_conducteur}`
                            : translate('None')}
                        </td>
                      )} */}
                      {selectedColumns.username_user && (
                        <td id="7">
                          {item.username_user !== "none"
                            ? `${item.username_user}`
                            : translate('None')}
                        </td>
                      )}
                      {selectedColumns.nom_groupe && (
                        <td id="5">
                          {item.nom_groupe !== "none"
                            ? item.nom_groupe
                            : translate('None')}
                        </td>
                      )}
                      {/* {selectedColumns.IB_CODE && (
                        <td id="tag-8" title={translate('Click to copy tag')} style={{ cursor: "pointer" }}>
                          <i className="las la-tags" style={{ marginRight: "4px" }}></i>
                          <span style={{ color: "rgb(0, 123, 255)" }}>
                            {item.IB_CODE !== "none" ? item.IB_CODE : translate('None')}
                          </span>
                        </td>
                      )} */}
                      {selectedColumns.GPSDIST && <td>{Distance(item.GPSDIST)}</td>}
                      <td>
                        <div className="d-flex align-items-center list-action">
                          <NavLink to={`/Vehicle/edit/${item.id_vehicule}`} className="badge bg-success mr-2" data-toggle="tooltip" data-placement="top" title={translate("Edit")}>
                            <i className="ri-pencil-line mr-0"></i>
                          </NavLink>
                          <a className="badge bg-warning mr-2" onClick={() => handleDeleteVehicle(item["id_vehicule"], IdUser)} data-toggle="tooltip" data-placement="top" title={translate("Delete")}>
                            <i className="ri-delete-bin-line mr-0"></i>
                          </a>
                        </div>
                      </td>
                    </tr>

                  ))
                ) : (

                  <tr>
                    <td colSpan={10}>No vehicles available</td>
                  </tr>

                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {list_vehicle.length} {translate("out of")}{" "}
            {total}
          </span>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={currentPage - 1}
          />
        </div>
        <VehicleModal
          show={modalStatus !== null}
          onHide={closeModal}
          status={modalStatus}
          title={titleStatus}
          IdUser={IdUser}
          IdVehicule={IdVehicule}
          updateVehicleList={updateVehicleList}
        />
        <DownloadModal
          show={showDownloadModal}
          onHide={() => setShowDownloadModal(false)}
          onDownloadConfirm={onDownloadConfirm}
        />
      </div>
    </>
  );
}
