/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { ListGroup, Image } from 'react-bootstrap';
import { useTranslate } from '../LanguageProvider';

interface Contact {
  id_user: number;
  nom_user: string;
  prenom_user: string;
  username_user: string;
  position: string;
  phone_number: string;
  email_user: string;
  status: string;
}



interface ContactProps {
  id_contact: number;
  handleContactClick: (chatId: any) => void;
}


const chats = [
  {
    id: 1,
    name: 'Waldemar Mannering',
    avatar: '../asset/images/avatars/13.png',
    status: 'online',
    lastMessage: 'Refer friends. Get rewards.',
    lastSeen: '5 Min'
  },
]

const ChatContacts: React.FC<ContactProps> = ({ id_contact, handleContactClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { translate } = useTranslate();
  const id_user = parseInt(localStorage.getItem("userID") ?? "0");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const [selectedContactId, setSelectedContactId] = useState<number | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [userLSender, setUser] = useState<Contact>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const getContacts = async () => {
    try {
      getUser()
      setLoading(true);
      const response = await fetch(`${backendUrl}/api/ticket/agents`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error retrieving contacts');
      }

      const data: Contact[] = await response.json();
      setContacts(data);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };



  const getUser = async () => {
 
    try {
      setLoading(true);
      const response = await fetch(`${backendUrl}/api/ticket/user/${id_user}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error retrieving contacts');
      }

      const data: Contact = await response.json();
      setUser(data);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
  
    getContacts();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

// Filter chat based on search
  const filteredChats = chats.filter(chat =>
    chat.name.toLowerCase().includes(searchTerm) ||
    chat.lastMessage.toLowerCase().includes(searchTerm)
  );

// Filter contacts based on search
  const filteredContacts = contacts.filter(contact =>
    contact.nom_user.toLowerCase().includes(searchTerm) ||
    contact.position.toLowerCase().includes(searchTerm)
  );

  const handleChatClick = (chatId: number) => {
    setSelectedChatId(chatId);
    setSelectedContactId(null);
  };


  return (

    <div className="col app-chat-contacts app-sidebar flex-grow-0 overflow-hidden border-end" id="app-chat-contacts">

      <div
        className={`col app-chat-sidebar-left app-sidebar overflow-hidden ${isSidebarOpen ? 'sidebar-open' : ''}`}
        id="app-chat-sidebar-left" style={{ background: "#fff", border: "1px solid #ddd" }}
      >
        <div className="sidebar-header h-px-75 px-5 border-bottom d-flex flex-column align-items-center text-center" style={{ margin: "15px 0",   height: "120px !important"}}>
          <div className="avatar avatar-xl avatar-online chat-sidebar-avatar" onClick={toggleSidebar}>
            <img src={`../asset/images/avatars/${userLSender?.id_user}.png` }alt="Avatar" className="rounded-circle" style={{ height: '41px' }} />
          </div>
          <h5 className="mt-2 mb-0">{userLSender?.nom_user+" "+userLSender?.prenom_user}</h5>
          <span className="mb-2">Admin</span>
          <i className="ti ti-x ti-lg cursor-pointer close-sidebar" onClick={toggleSidebar}></i>
        </div>

        <div className="sidebar-body px-6 pb-6" style={{overflowY: "auto"}}>
          <div className="my-6">
            <label htmlFor="chat-sidebar-left-user-about" className="text-uppercase text-muted mb-1">About</label>
            <textarea id="chat-sidebar-left-user-about" className="form-control chat-sidebar-left-user-about" rows={3} maxLength={120}>
              Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.</textarea>
          </div>
          <div className="my-6">
            <p className="text-uppercase text-muted mb-1">Status</p>
            <div className="d-grid gap-2 pt-2 text-heading ms-2">
              <div className="form-check form-check-success">
                <input name="chat-user-status" className="form-check-input" type="radio" value="active" id="user-active" defaultChecked />
                <label className="form-check-label" htmlFor="user-active">Online</label>
              </div>
              <div className="form-check form-check-warning">
                <input name="chat-user-status" className="form-check-input" type="radio" value="away" id="user-away" />
                <label className="form-check-label" htmlFor="user-away">Away</label>
              </div>
              <div className="form-check form-check-danger">
                <input name="chat-user-status" className="form-check-input" type="radio" value="busy" id="user-busy" />
                <label className="form-check-label" htmlFor="user-busy">Do not Disturb</label>
              </div>
              <div className="form-check form-check-secondary">
                <input name="chat-user-status" className="form-check-input" type="radio" value="offline" id="user-offline" />
                <label className="form-check-label" htmlFor="user-offline">Offline</label>
              </div>
            </div>
          </div>
          <div className="my-6">
            <p className="text-uppercase text-muted mb-1">Settings</p>
            <ul className="list-unstyled d-grid gap-4 ms-2 pt-2 text-heading">
              <li className="d-flex justify-content-between align-items-center">
                <div>
                  <i className="ti ti-lock ti-md me-1"></i>
                  <span className="align-middle">Two-step Verification</span>
                </div>
                <div className="form-check form-switch mb-0 me-1">
                  <input type="checkbox" className="form-check-input" defaultChecked />
                </div>
              </li>
              <li className="d-flex justify-content-between align-items-center">
                <div>
                  <i className="ti ti-bell ti-md me-1"></i>
                  <span className="align-middle">Notification</span>
                </div>
                <div className="form-check form-switch mb-0 me-1">
                  <input type="checkbox" className="form-check-input" />
                </div>
              </li>
             
             
            </ul>
          </div>
        </div>
      </div>

      <div className="sidebar-header px-4 border-bottom d-flex align-items-center">
        <div className="d-flex align-items-center me-6 me-lg-0">
          <div className="flex-shrink-0 avatar avatar-online me-4" onClick={toggleSidebar}>
            <img className="user-avatar rounded-circle cursor-pointer" src="../asset/images/avatars/1.png" alt="Avatar" style={{ height: '41px' }} />
          </div>
          <div className="flex-grow-1 input-group input-group-merge">
            <span className="input-group-text" id="basic-addon-search31">
              <i className="las la-search"></i>
            </span>
            <input
              type="text"
              className="form-control chat-search-input"
              placeholder="Search..."
              aria-label="Search..."
              aria-describedby="basic-addon-search31"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <i className="ti ti-x ti-lg cursor-pointer position-absolute top-50 end-0 translate-middle d-lg-none d-block"></i>
      </div>

      <div className="sidebar-body" style={{ padding: "5px" }}>
        <ListGroup.Item as="li" style={{ border: "0px" }}>
          <h5 className="text-primary mb-0">{translate("Ticket")+"s"}</h5>
        </ListGroup.Item>

        <ListGroup as="ul" className="list-unstyled chat-contact-list py-2 mb-0" id="chat-list" style={{ height: 'auto', maxHeight: "300px", overflowY: 'auto' }}>
          {filteredChats.length === 0 ? (
            <ListGroup.Item as="li" className="chat-contact-list-item">
              <h6 className="text-muted mb-0">No Chats Found</h6>
            </ListGroup.Item>
          ) : (
            filteredChats.map(chat => (
              <ListGroup.Item
                key={chat.id}
                as="span"
                onClick={() => handleChatClick(chat.id)}
                className={`chat-contact-list-item ${selectedChatId === chat.id ? 'selected-chat' : ''}`}
                style={{ cursor: 'pointer', border: selectedChatId === chat.id ? '2px solid #007bff' : 'none' }} 
              >
                <span className="d-flex align-items-center">
                  <div className={`flex-shrink-0 avatar avatar-${chat.status}`}>
                    {chat.avatar ? (
                      <Image src={chat.avatar} alt="Avatar" className="rounded-circle" style={{ height: '41px' }} />
                    ) : (
                      <span className="avatar-initial rounded-circle bg-label-success">{ }</span>
                    )}
                  </div>
                  <div className="chat-contact-info flex-grow-1 ms-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="chat-contact-name text-truncate fw-normal m-0">{chat.name}</h6>
                      <small className="">{chat.lastSeen}</small>
                    </div>
                    <small className="chat-contact-status text-truncate">
                      {chat.lastMessage.length > 10 ? `${chat.lastMessage.substring(0, 10)}...` : chat.lastMessage}
                    </small>
                  </div>
                </span>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>

        {/* List of contacts */}
        <ListGroup.Item as="li" style={{ border: "0px" }}>
          <h5 className="text-primary mb-0">Contacts</h5>
        </ListGroup.Item>
        <ListGroup as="ul" className="list-unstyled chat-contact-list mb-0 py-2" id="contact-list" style={{ height: '400px', overflowY: 'auto' }}>
          {filteredContacts.length === 0 ? (
            <ListGroup.Item as="li" className="chat-contact-list-item">
              <h6 className="text-muted mb-0">No Contacts Found</h6>
            </ListGroup.Item>
          ) : (
            filteredContacts
              .filter(contact => contact.id_user !== id_contact) 
              .map(contact => (
                <ListGroup.Item
                  key={contact.id_user}
                  onClick={() => handleContactClick(contact)}
                  className={`chat-contact-list-item ${selectedContactId === contact.id_user ? 'selected-chat' : ''}`}
                  style={{ cursor: 'pointer',border: selectedContactId === contact.id_user ? '2px solid #007bff' : 'none'}}
                  as="span"
                >
                  <span className="d-flex align-items-center">
                    <div className={`flex-shrink-0 avatar ${contact.status}`}>
                      {contact.id_user ? (
                        <Image
                          src={ `../asset/images/avatars/${contact.id_user}.png`}
                          alt="Avatar"
                          className="rounded-circle"
                          style={{ height: '41px' }}
                        />
                      ) : (
                        <span className={`avatar-initial rounded-circle ${contact.status}`}>
                          {contact.nom_user[0] + "." + contact.prenom_user[0]}
                        </span>
                      )}
                    </div>
                    <div className="chat-contact-info flex-grow-1 ms-4">
                      <h6 className="chat-contact-name text-truncate m-0 fw-normal">
                      {contact.nom_user + " " + contact.prenom_user}
                      </h6>
                      <small className="chat-contact-status text-truncate">
                        {contact.position}
                      </small>
                    </div>
                  </span>
                </ListGroup.Item>
              ))

          )}
        </ListGroup>
      </div>
    </div>
  );
};

export default ChatContacts;