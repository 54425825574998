/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../components/LanguageProvider";
import { PropagateLoader } from "react-spinners";
import AlarmGroupModal from "../components/Alarm/Modals/GroupAlarm";
import { Link } from 'react-router-dom';
import DeleteGroupAlarmModal from "../components/Alarm/Modals/deleteGroupAlarm";

interface GroupAlarmsProps {
  id_groupe_alarme: number;
  id_user: string;
  name_groupe_alarme: string;
  description: string;
  notify_email: boolean;
  notify_app: boolean;
  notify_type_app_list: number[];    // List of strings for alert types via app
  notify_user_app_list: number[];    // List of selected user IDs
  notify_mail_list: number[];
  username_user: string;
}

export function GroupAlarms() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { translate } = useTranslate();
  let [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list_GroupAlarms, setGroupAlarms] = useState<GroupAlarmsProps[]>([]);
  const id_user = localStorage.getItem("userID");
  const [showCreateAlarmModal, setShowCreateAlarmModal] = useState(false);
  const handleShowCreateAlarmModal = () => setShowCreateAlarmModal(true);
  const handleCloseCreateAlarmModal = () => setShowCreateAlarmModal(false);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  let [total, setTotal] = useState(0);
  const [colum, setSortColum] = useState("id_alarme");
  const [sort, setSort] = useState("ASC");
  const [search, setSearch] = useState("");
  const [type, setType] = useState(0);
  const [typeSearch, setTypeSearch] = useState("Group ID");
  const [idGroupAlarm, setIdGroupAlarm] = useState(0);
  const [modalStatus, setModalStatus] = useState<string | null>(null);
  const [titleStatus, setTitleStatus] = useState<string | null>(null);
  const [IdUser, setIdUser] = useState<string>("0");


  const handleDeleteGroupAlarm = async (id_groupe_alarme: number, IdUser: string) => {
    try {
      console.log(id_groupe_alarme);
      setModalStatus('Are you sure you want to delete this alarm group? All associated configurations for email notification will be permanently lost.');
      setTitleStatus('Delete group alarm');
      setIdUser(IdUser);
      setIdGroupAlarm(id_groupe_alarme);
    } catch (error) {
      console.error(error);
    }
  };


  const closeModal = () => {
    setModalStatus(null);
  };


  useEffect(() => {
    if (modalStatus === 'success') {
      const timeoutId = setTimeout(() => {
        setModalStatus(null);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [modalStatus]);



  const getGroupeAlarm = async (
    limitValue: number,
    currentPage: number,
    search: string,
    type: number,
    colum: string,
    sort: string
  ) => {
    try {
      setLoading(true);

      // Preparing the data to send
      const bodyData = JSON.stringify({
        limitValue,
        currentPage,
        search,
        type,
        id_user,
        colum,
        sort,
      });

      // Retrieve the total number of pages
      const totalPagesResponse = await fetch(
        `${backendUrl}/api/alarm/group/totalpage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const totalPagesJson = await totalPagesResponse.json();
      const total = totalPagesJson[0]["count"];
      setTotal(total);

      // Retrieving alarm data
      const GroupAlarmsResponse = await fetch(`${backendUrl}/api/alarm/group/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      const data = await GroupAlarmsResponse.json();
      setPageCount(Math.ceil(total / limitValue));
      setLimit(limitValue);
      setGroupAlarms(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const refreshAlarmGroups = () => {
    getGroupeAlarm(limit, currentPage, search, type, colum, sort);
  };


  const getGroupeAlarmLimitValue = async (
    limitValue: number,
    currentPage: number,
    search: string,
    type: number,
    colum: string,
    sort: string
  ) => {
    try {
      setLoading(true);

      /// Preparing the data to send
      const bodyData = JSON.stringify({
        limitValue,
        currentPage,
        search,
        type,
        id_user,
        colum,
        sort,
      });

      // Retrieve the total number of pages
      const totalPagesResponse = await fetch(
        `${backendUrl}/api/alarm/group/totalpage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const totalPagesJson = await totalPagesResponse.json();
      const total = totalPagesJson[0]["count"];
      setTotal(total);

      // Retrieving alarm data
      const GroupAlarmsResponse = await fetch(`${backendUrl}/api/alarm/group/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      const data = await GroupAlarmsResponse.json();
      setPageCount(Math.ceil(total / limitValue));
      setLimit(limitValue);

      return data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    await getGroupeAlarm(
      limit,
      currentPage,
      search,
      type,
      colum,
      sort
    );
    // setGroupAlarms(commentsForServer);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getGroupeAlarm(limit, currentPage, search, type, colum, sort);
  }, []);

  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setCurrentPage(1); // Reset currentPage to 1 when limit changes
    setLimit(newValue);
    const commentsForServer = await getGroupeAlarmLimitValue(
      parseInt(newValue),
      1,
      search,
      type,
      colum,
      sort
    );
    setGroupAlarms(commentsForServer);
    window.scrollTo(0, 0);
  };

  const [selectedColumns, setSelectedColumns] = useState({
    id_groupe_alarme: true,
    name_groupe_alarme: true,
    id_user: true,
    creation: true,
    description: true,
    username_user: true,
  });

  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleTypeSearch = (event: any) => {
    const selectedValue = event.target.textContent;


    switch (selectedValue) {
      case translate("Group ID"):
        setType(0);
        break;
      case translate("Group name"):
        setType(1);
        break;
      case translate("Description"):
        setType(2);
        break;
      case translate("User"):
        setType(3);
        break;
      case translate("Notification"):
        setType(4);
        break;
      default:
        console.log("Unknown selection");
        break;
    }

    setTypeSearch(selectedValue);
    console.log("Selected value:", selectedValue);
  };

  const handleAdvancedSearch = async (event: any) => {
    const newValue = event.target.value;
    setSearch(newValue);
    await getGroupeAlarm(limit, currentPage, newValue, type, colum, sort);
  };

  const handleSortingColum = (currentColumn: string) => {
    setSortColum(currentColumn);
    sort == "ASC" ? setSort("DESC") : setSort("ASC");
    setCurrentPage(1)
    getGroupeAlarm(limit, 1, search, type, colum, sort);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h4>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-bell"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
              </svg>
            </span>
            {translate("Group alarms")} ({total})
          </h4>
        </div>
        <div className="col-md-6 col-sm-12 text-right">

          <Link
            to={`/alarms`}
            className="btn btn-outline-secondary  mr-2"
            data-toggle="Cancel"
            data-placement="top"
            title="Cancel"
          >
            <i className="fas fa-chevron-left" style={{ fontSize: "1.2em" }}></i>
            {translate("Alarms")}
          </Link>

          <Button
            variant=""
            className="mr-1 btn btn-outline-secondary"
            onClick={handleShowCreateAlarmModal}
          >
            <i className="las la-plus mr-3"></i>Add group
          </Button>

        </div>
      </div>
      <div className="row">
        <div
          className="col-md-4"
          style={{ margin: "0px 0px 10px 0px", padding: "10px" }}
        >
          <div className="input-group">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i
                  className="fas fa-chevron-down"
                  style={{ fontSize: "20" }}
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu onClick={handleTypeSearch}>
                <Dropdown.Item>{translate("Group ID")}</Dropdown.Item>
                <Dropdown.Item>{translate("Group Name")}</Dropdown.Item>
                <Dropdown.Item>{translate("Description")}</Dropdown.Item>
                <Dropdown.Item>{translate("User")}</Dropdown.Item>
                <Dropdown.Item>{translate("Notification")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <input
              type="text"
              placeholder={` By ${typeSearch}`}
              onChange={handleAdvancedSearch}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-8 d-flex justify-content-end align-items-center">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label style={{ marginBottom: "0" }}>
              {translate("Show")}
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                style={{ width: "66px" }}
                onChange={handleSelectChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </label>
          </div>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              title={translate("Display columns")}
            >
              <i className="las la-eye"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.id_groupe_alarme}
                  onChange={() => handleColumnChange("id_groupe_alarme")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Alarm ID")}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.id_groupe_alarme}
                  onChange={() => handleColumnChange("id_groupe_alarme")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Group ID")}</span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.name_groupe_alarme}
                  onChange={() => handleColumnChange("name_groupe_alarme")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Group name")}</span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.description}
                  onChange={() => handleColumnChange("description")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Description")}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.username_user}
                  onChange={() => handleColumnChange("username_user")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("User")}
                </span>
              </Dropdown.Item>


            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="row m-1">
        <Table className="dataTable">
          <thead className="bg-white text-uppercase">
            <tr className="ligth ligth-data">
              <th>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label"></label>
                </div>
              </th>
              {selectedColumns.id_groupe_alarme && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("id_groupe_alarme")}
                >
                  {translate("Group ID")}
                </th>
              )}
              {selectedColumns.name_groupe_alarme && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("name_groupe_alarme")}
                >
                  {translate("Group name")}
                </th>
              )}
              {selectedColumns.description && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("description")}
                >
                  {translate("Description")}
                </th>
              )}
              {selectedColumns.username_user && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("username_user")}
                >
                  {translate("User")}
                </th>
              )}

              {<th>{translate("Actions")}</th>}
            </tr>
          </thead>
          <tbody key="#" className="ligth-body">
            {loading ? (
              <tr>
                <td className="text-center" colSpan={7}>
                  <p>
                    <PropagateLoader
                      color={"#123abc"}
                      loading={loading}
                      size={20}
                    /></p>
                </td>
              </tr>
            ) : list_GroupAlarms.length > 0 ? (
              list_GroupAlarms.map((groupAlarm) => (
                <tr key={groupAlarm.id_groupe_alarme}>
                  <td>
                    <div className="form-check form-check-inline">
                      <input type="checkbox" className="form-check-input" />
                    </div>
                  </td>
                  {selectedColumns.id_groupe_alarme && <td>{groupAlarm.id_groupe_alarme}</td>}
                  {selectedColumns.name_groupe_alarme && <td>{groupAlarm.name_groupe_alarme}</td>}
                  {selectedColumns.description && <td>{groupAlarm.description}</td>}
                  {selectedColumns.username_user && <td>{groupAlarm.username_user}</td>}

                  <td>
                    <div className="d-flex align-items-center list-action">
                      <a className="badge badge-success mr-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={translate("Edit alarm")}
                        onClick={() => {
                          handleShowCreateAlarmModal();
                          setIdGroupAlarm(groupAlarm.id_groupe_alarme);
                        }}
                      >
                        <i
                          className="las la-cog"
                          style={{ fontSize: "1.2em" }}
                        ></i>
                      </a>
                      <a
                        className="badge bg-warning mr-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={translate("Notification Methods")}
                        data-original-title="Delete"
                        onClick={() => handleDeleteGroupAlarm(groupAlarm.id_groupe_alarme, id_user || "0")}
                      >
                        <i
                          className="las la-trash "
                          style={{ fontSize: "1.2em" }}
                        ></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No alarm available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {list_GroupAlarms.length} {translate("out of")}{" "}
            {total}
          </span>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
        <AlarmGroupModal
          show={showCreateAlarmModal}
          onHide={handleCloseCreateAlarmModal}
          refreshAlarmGroups={refreshAlarmGroups}
          idAlarmGroup={idGroupAlarm}
        />
        <DeleteGroupAlarmModal
          show={modalStatus !== null}
          onHide={closeModal}
          status={modalStatus}
          title={titleStatus}
          IdUser={IdUser}
          idGroupAlarm={idGroupAlarm}
          updateGroupAlarmList={refreshAlarmGroups}
        />
      </div>
    </>
  );
}
