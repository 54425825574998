/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Image, Dropdown, Navbar as NavbarBs, NavLink, Badge, Card, InputGroup, ListGroup } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslate } from './LanguageProvider';
import Cookies from 'universal-cookie';
import Logout from './Logout';
import NetworkStatusIcon from './NetworkStatusIcon';
import { toast, Bounce } from 'react-toastify';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

interface NavbarProps {
  changNavbar: boolean;
  onToggleSidebarInNavbar: () => void;
}

interface Notification {
  id_notification: number,
  id_groupe_alarme: number,
  id_user: number,
  id_vehicule: number,
  id_conducteur: number,
  type: string,
  message: string,
  severity: number,
  timestamp: string,
  state: number,
}


const Navbar: React.FC<NavbarProps> = ({ onToggleSidebarInNavbar, changNavbar }) => {

  const userName = localStorage.getItem("username");
  const [isOpen, setIsOpen] = useState("");
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [activeLogo, setactiveLogo] = useState("header-logo-show");
  const [activeMenuText, setactiveMenuText] = useState("");
  const [menuBtsidebar, setMenuBtsidebar] = useState("iq-menu-bt-sidebar-show");
  const [sidebar, setSidebar] = useState("sidebar-open");
  const [shownenu, setShownenu] = useState("");
  const { lang, setLang } = useTranslate();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const handleLanguageChange = (newLang: any) => { setLang(newLang); };
  const userID = localStorage.getItem("userID");
  const apikey = localStorage.getItem("api_key");
  const strLang = {
    en: translate('English'),
    fr: translate('French'),
    ar: translate('Arabic'),
    es: translate('Espagnol'),
  };


  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Supprimer le token du localStorage
    localStorage.removeItem("userID"); // Supprimer le token du localStorage
    const cookies = new Cookies();
    // cookies.remove('jwtToken');
    navigate("/login"); // Rediriger l'utilisateur vers la page de Connection
  };
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "logoutEvent") {
        // La déConnection s'est produite, déconnectez l'utilisateur
        handleLogout();
      }
    };

    // Ajouter un écouteur d'événement de stockage local
    window.addEventListener("storage", handleStorageChange);

    // Nettoyer l'écouteur lors du démontage du composant
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    let inactivityTimeout: NodeJS.Timeout;

    const handleActivity = () => {
      clearTimeout(inactivityTimeout); // Reset the timeout on user activity
      inactivityTimeout = setTimeout(() => {
        // Auto logout after 2 minutes of inactivity
        handleLogout();
      }, 900000); // 2 minutes in milliseconds
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'logoutEvent') {
        handleLogout();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearTimeout(inactivityTimeout);
    };
  }, []);


  const [dropdownOpenNofification, setDropdownOpenNofification] = useState(false);
  const [dropdownOpenProfile, setDropdownOpenProfile] = useState(false);

  const handleToggleNofification = (DropdownOpen: any) => {
    setDropdownOpenNofification(DropdownOpen);
  };


  const handleToggleProfile = (DropdownOpen: any) => {
    setDropdownOpenProfile(DropdownOpen);
  };


  const handleSetIsOpen = () => {
    isOpen == "open" ? setIsOpen("") : setIsOpen("open");

    sidebar == "sidebar-close"
      ? setSidebar("sidebar-open")
      : setSidebar("sidebar-close");

    activeLogo == "header-logo-show"
      ? setactiveLogo("header-logo-hide")
      : setactiveLogo("header-logo-show");
    activeMenuText == "iq-menu-span-hide"
      ? setactiveMenuText("")
      : setactiveMenuText("iq-menu-span-hide");
    menuBtsidebar == "iq-menu-bt-sidebar-show"
      ? setMenuBtsidebar("iq-menu-bt-sidebar-hide")
      : setMenuBtsidebar("iq-menu-bt-sidebar-show");
  };

  const toggleMenu = () => {
    setShownenu(shownenu === "" ? "show" : "");
  };


  const getAlarms = async (id_user: string, state: number) => {
    try {
      // Prepare the request body
      const bodyData = JSON.stringify({
        id_user: parseInt(id_user),
        state
      });

      // Make the fetch request
      const response = await fetch(
        `${backendUrl}/api/notification/read`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      // Check if the response status is OK (status code 200)
      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}: ${response.statusText}`);
      }

      // Parse the JSON response
      const alarmResponse = await response.json();

      // Set the notifications state
      setNotifications(alarmResponse);
    } catch (error) {
      // Handle errors (network errors or response errors)
      console.error("Error fetching alarms:", error);

      // Optionally, update the state to handle errors in the UI
      setNotifications([]);
      // Display a user-friendly message or handle the error in your UI logic
      // alert("An error occurred while fetching alarms. Please try again later.");
    }
  };



  useEffect(() => {
    if (userID) {
      getAlarms(userID, 1);

      const interval = setInterval(() => {
        getAlarms(userID, 1);
      }, 10000); // 180000ms = 3 minutes

      return () => clearInterval(interval);
    }
  }, [userID]);

  const handleToggleNotification = () => {
    setDropdownOpenNofification(!dropdownOpenNofification);
  };



  interface Page {
    id: number;
    title: string;
    content: string;
    route: string;
  }

  const pages: Page[] = [
    { id: 1, title: translate("Dashboard"), content: translate("Main dashboard displaying an overview of your fleet management."), route: "/" },
    { id: 2, title: translate("Map"), content: translate("Interactive map showing the location of all vehicles and devices."), route: "/map" },
    { id: 3, title: translate("Vehicles"), content: translate("List of all vehicles in the fleet, including details and management options."), route: "/vehicles" },
    { id: 4, title: translate("Add Vehicle"), content: translate("Form to add a new vehicle to the fleet."), route: "/Vehicle/add" },
    { id: 5, title: translate("Edit Vehicle"), content: translate("Form to edit an existing vehicle's details."), route: "/vehicles" },
    { id: 6, title: translate("Devices"), content: translate("List of all devices in the fleet, including their status and details."), route: "/Devices" },
    { id: 7, title: translate("Add Device"), content: translate("Form to add a new device."), route: "/Device/add" },
    { id: 8, title: translate("Edit Device"), content: translate("Form to edit an existing device's details."), route: "/Devices" },
    { id: 9, title: translate("Drivers"), content: translate("List of all drivers, including their profiles and management options."), route: "/drivers" },
    { id: 10, title: translate("Driver Profile"), content: translate("Detailed profile of a specific driver."), route: "/driver" },
    { id: 11, title: translate("Driver Details"), content: translate("Detailed information about a specific driver."), route: "/drivers" },
    { id: 12, title: translate("Fleet"), content: translate("Overview of the entire fleet, including vehicle and driver statistics."), route: "/Fleet" },
    { id: 14, title: translate("SIM Cards"), content: translate("Manage SIM cards used in devices."), route: "/cartes-sim" },
    { id: 15, title: translate("Users"), content: translate("List of users who have access to the system, including their roles and permissions."), route: "/users" },
    { id: 16, title: translate("User Profile"), content: translate("Profile page for a specific user."), route: "/User" },
    { id: 17, title: translate("Ibutton"), content: translate("Manage Ibutton devices."), route: "/Ibutton" },
    { id: 18, title: translate("Reports"), content: translate("Generate and view various reports related to fleet performance and other metrics."), route: "/reports" },
    { id: 19, title: translate("POI Reports"), content: translate("Reports on points of interest."), route: "/poi-reports" },
    { id: 20, title: translate("New POI Report"), content: translate("Form to create a new point of interest report."), route: "/new-poi-report" },
    { id: 21, title: translate("Fleet Reports"), content: translate("Reports related to fleet operations and performance."), route: "/fleet-reports" },
    { id: 22, title: translate("New Fleet Report"), content: translate("Form to create a new fleet report."), route: "/new-fleet-report" },
    { id: 23, title: translate("Snapshots"), content: translate("View and manage snapshots of fleet data."), route: "/snapshots" },
    { id: 24, title: translate("Report Details"), content: translate("Detailed view of a specific report."), route: "/reports" },
    { id: 25, title: translate("New Report"), content: translate("Form to create a new report."), route: "/new-report" },
    { id: 26, title: translate("Tag History"), content: translate("History of tags and their usage."), route: "/history-tag" },
    { id: 27, title: translate("Vehicle History"), content: translate("History and logs related to vehicles."), route: "/history-vehucle" },
    { id: 28, title: translate("User History"), content: translate("History and logs related to users."), route: "/history-user" },
    { id: 29, title: translate("Help"), content: translate("Help and support resources for using the platform."), route: "/help" },
    { id: 30, title: translate("Log Positions"), content: translate("View logs of positions for devices and vehicles."), route: "/logpositions" },
    { id: 31, title: translate("Connection"), content: translate("Page for connecting to the platform."), route: "/Connection" },
    { id: 32, title: translate("Driver Tag"), content: translate("Manage driver tags and associated data."), route: "/driver-tag" },
    { id: 33, title: translate("Settings"), content: translate("Platform settings and configuration options."), route: "/Settings" },
    { id: 34, title: translate("User Profile by ID"), content: translate("Detailed profile of a specific user identified by userurlID."), route: "/User" },
    { id: 35, title: translate("Vehicle Groups"), content: translate("Manage groups of vehicles."), route: "/groupevehicles" },
    { id: 36, title: translate("Device Groups"), content: translate("Manage groups of devices."), route: "/groupe-device" },
    { id: 37, title: translate("Roles"), content: translate("Manage user roles and permissions."), route: "/role" },
    { id: 38, title: translate("Role Permissions"), content: translate("Manage permissions for a specific role and user."), route: "/role" },
    { id: 39, title: translate("Profile"), content: translate("User profile page."), route: "/profile" },
    { id: 40, title: translate("Support Tickets"), content: translate("Manage support tickets from customers."), route: "/support-customers" },
    { id: 41, title: translate("Alarms"), content: translate("Manage and view alarms."), route: "/alarms" },
    { id: 42, title: translate("Add Alarm"), content: translate("Form to add a new alarm."), route: "/alarm/add" },
    { id: 43, title: translate("Edit Alarm"), content: translate("Form to edit an existing alarm."), route: "/alarms" },
    { id: 44, title: translate("Group Alarms"), content: translate("Manage groups of alarms."), route: "/group-alarms" },
    { id: 45, title: translate("Points of Interest"), content: translate("Manage points of interest (POIs)."), route: "/pois" },
    { id: 46, title: translate("Edit POI"), content: translate("Form to edit an existing point of interest."), route: "/pois" },
    { id: 47, title: translate("Add POI"), content: translate("Form to add a new point of interest."), route: "/poi/add" },
    { id: 48, title: translate("Notifications"), content: translate("View and manage notifications."), route: "/notifications" },
    // { id: 49, title: translate("Chat"), content: translate("Real-time chat functionality for communication."), route: "/chat" },
    // { id: 50, title: translate("Login"), content: translate("Login page for accessing the platform."), route: "/login" },
  ];



  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredPages, setFilteredPages] = useState<Page[]>(pages);
  const [isSearchVisible, setSearchVisible] = useState(true);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term)
    setSearchVisible(true);;
    setFilteredPages(
      pages.filter(
        page =>
          page.title.toLowerCase().includes(term) ||
          page.content.toLowerCase().includes(term)
      )
    );
  };

  const handleItemClick = () => {
    // Masquer le div de recherche lorsque l'élément est cliqué
    setSearchVisible(false);
  };


  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);

  // Fonction pour basculer entre les thèmes
  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    // Modifier la classe du body en fonction du thème
    if (isDarkTheme) {
      document.body.classList.remove("dark-theme");
      document.body.classList.add("light-theme");
    } else {
      document.body.classList.remove("light-theme");
      document.body.classList.add("dark-theme");
    }
  };

  // Utiliser le useEffect pour sauvegarder le thème dans le localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setIsDarkTheme(true);
      document.body.classList.add("dark-theme");
    } else {
      setIsDarkTheme(false);
      document.body.classList.add("light-theme");
    }
  }, []);

  // Sauvegarder le thème sélectionné dans le localStorage
  useEffect(() => {
    localStorage.setItem("theme", isDarkTheme ? "dark" : "light");
  }, [isDarkTheme]);

  return (
    <div className={`iq-top-navbar  ${changNavbar ? "navbar-push" : "navbar-pool"}`} >
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
            <i className={` wrapper-menu `}
              onClick={() => {
                handleSetIsOpen();
                onToggleSidebarInNavbar();
              }}
            > </i>

            <Nav.Link to="/" className={`header-logo`} as={NavLink}>
              <Image className="img-fluid rounded-normal light-logo" style={{ height: "22px", width: "136px", paddingLeft: 15 }} src="asset/images/logo.png" ></Image>
            </Nav.Link>


            <div className="search-container  hide-on-mobile">
              <InputGroup className="">
                <InputGroup.Text id="search-icon">
                  <i className="fas fa-search"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder={translate("Search here") + "..."}
                />
              </InputGroup>
              {isSearchVisible && searchTerm && filteredPages.length > 0 && (
                <ListGroup
                  className="results-container"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    // width: "100%",
                    maxHeight: "400px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    zIndex: 10,
                    overflowY: "auto"
                  }}
                >
                  {filteredPages.map(page => (
                    <ListGroup.Item
                      key={page.id}
                      className="result-item"
                      onClick={handleItemClick}
                    >
                      <strong><Link to={page.route}>{page.title}</Link></strong>: {page.content}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </div>


          </div>

          <div className="d-flex align-items-center">

            <button onClick={toggleMenu} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation" aria-expanded="true">
              <i className="ri-menu-3-line"></i>
            </button>



            <div className={`navbar-collapse collapse ${shownenu}`} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto navbar-list align-items-center">


                <li className="nav-item nav-icon dropdown" style={{ margin: "0 5px" }} title={translate("Click here to access GEOPARC")}>

                  {userID === "1"}  <a href={`https://geoparc.geotrackin.com?apikey=${apikey}`} className="gp">
                    <i className="las la-sign-in-alt" style={{ fontSize: "29px" }}></i>
                    <span style={{ fontSize: "16px" }}> GEOPARC</span>
                  </a>

                </li>


                <li className="nav-item nav-icon dropdown" style={{ margin: "0 5px" }}>
                  <NavDropdown
                    title={
                      <span>
                        <img src={`asset/images/small/flag-${lang}.png`} alt={lang} />
                        <span> {strLang[lang]}</span>
                      </span>
                    }
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item onClick={() => handleLanguageChange('en')}>
                      <img src="asset/images/small/flag-en.png" alt="img-flag" className="img-fluid image-flag mr-2" /> {translate("English")}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleLanguageChange('fr')}>
                      <img src="asset/images/small/flag-fr.png" alt="img-flag" className="img-fluid image-flag mr-2" /> {translate("French")}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleLanguageChange('ar')}>
                      <img src="asset/images/small/flag-ar.png" alt="img-flag" className="img-fluid image-flag mr-2" /> {translate("Arabic")}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleLanguageChange('es')}>
                      <img src="asset/images/small/flag-es.png" alt="img-flag" className="img-fluid image-flag mr-2" /> {translate("Spanish")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>



                <li className="nav-item nav-icon dropdown" style={{ margin: "0 5px" }}>
                  <div className="dropdown" onClick={toggleTheme} style={{ cursor: "pointer" }}>
                    {/* Afficher l'icône en fonction du thème */}
                    {isDarkTheme ? (
                      <i className="las la-moon" style={{ fontSize: "22px" }}></i> // Icône lune pour le thème sombre
                    ) : (
                      <i className="las la-sun" style={{ fontSize: "22px" }}></i> // Icône soleil pour le thème clair
                    )}
                  </div>
                </li>

                <li className="nav-item nav-icon dropdown show" style={{ margin: "0 5px" }}>
                  <Dropdown onToggle={handleToggleNotification}>
                    <Dropdown.Toggle as="span" id="dropdownMenuButton5" className="search-toggle" href="#">
                      <div className="notification-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bell"
                        >
                          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                        </svg>
                        {notifications && notifications.length > 0 && (
                          <span className="notification-badge">{notifications.length}</span>
                        )}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="iq-sub-dropdown dropdown-menu" show={dropdownOpenNofification} aria-labelledby="dropdownMenuButton5">
                      <Card className="shadow-none m-0">
                        <Card.Body className="p-0">
                          <div className="cust-title p-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Notifications</h5>
                              <Badge className="badge-card">{notifications && notifications.length}</Badge>
                            </div>
                          </div>
                          <div className="px-3 pt-0 pb-0 sub-card">
                            {notifications && notifications.map((notification) => (
                              <a href="#" className="iq-sub-card" key={notification.id_notification}>
                                <div className="media align-items-center cust-card py-3 border-bottom" style={{ paddingBottom: "0.5rem !important" }}>
                                  <div>
                                    <img className="avatar-30 rounded-small" src="../asset/images/icon-report/3.png" alt="01" />
                                  </div>
                                  <div className="media-body ml-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <h6 className="mb-0">{notification.type}</h6>
                                      <small className="text-dark">
                                        <b>{new Date(notification.timestamp).toLocaleTimeString()}</b>
                                      </small>
                                    </div>
                                    <small className="mb-0">{notification.message}</small>
                                  </div>
                                </div>
                              </a>
                            ))}
                          </div>
                          <button
                            className="right-ic btn btn-primary btn-block position-relative p-2"
                            onClick={() => (window.location.href = "/notifications")}
                          >
                            View All
                          </button>
                        </Card.Body>
                      </Card>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="nav-item nav-icon dropdown" style={{ margin: "0 5px" }}>
                  <Dropdown onToggle={handleToggleProfile} show={dropdownOpenProfile}>
                    <Dropdown.Toggle as="span" id="dropdownMenuButton4" className="search-toggle" href="#">
                      {/* <img src="asset/images/user/1.png" alt="user" style={{ }} className="img-fluid rounded" /> */}
                      <i className='las la-user' style={{ fontSize: "25px" }}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "auto", inset: "auto auto auto auto" }} className='dropdownProfile'>
                      <div className="card shadow-none border-0 m-0">
                        <div className="card-body p-0 text-center">
                          <div className="media-body profile-detail text-center">
                            <img src="asset/images/page-img/profile-bg.jpg" alt="profile-bg" className="rounded-top img-fluid mb-4" />
                            <img src="asset/images/user/blank.png" alt="profile-img" className="rounded profile-img img-fluid avatar-70" />
                          </div>
                          <div className="p-3">
                            <h5 className="mb-1">{userName}</h5>
                            <p className="mb-0"></p>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              <Button variant="outline-secondary" className="mr-2" onClick={() => navigate(`/profile`)} style={{ minWidth: "max-content", border: "1px solid #ddd", borderRadius: "5px" }}>
                                <i className="las la-user"></i> {translate("Profile")}
                              </Button>
                              <span style={{ minWidth: "max-content", border: "1px solid #ddd", borderRadius: "5px" }}>
                                <Logout onLogout={handleLogout} activeMenu={""} title={translate("Logout")} margin={'ml-1'} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
