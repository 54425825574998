import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Bounce, toast } from "react-toastify";
import { useTranslate } from "../../LanguageProvider";
import FileUploadDragDrop from "./FileUploadDragDrop";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Interface for ticket information
interface TicketInfo {
  id_ticket: number | null;
  agent: number | null;
  description: string | null;
  title: string | null;
  id_user: number | null;
  nom_user: string | null;
  creation_date: string | null;
  priority: number | null;
  status: number | null;
  criticality: number | null;
  id_category: number | null;
  id_subcategory: number | null;
  category_name: string | null;
  subcategory_name: string | null;
}

interface CreateTicketModalProps {
  show: boolean;
  onHide: () => void;
  refreshTickets: () => void;
  id_ticket: number;
}

const CreateTicketModal: React.FC<CreateTicketModalProps> = ({
  show,
  onHide,
  refreshTickets,
  id_ticket,
}) => {
  const userID = localStorage.getItem("userID");
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useTranslate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [ticketInfo, setTicketInfo] = useState<TicketInfo>({
    id_ticket: null,
    agent: null,
    description: null,
    title: null,
    id_user: null,
    nom_user: null,
    creation_date: null,
    priority: null,
    status: null,
    criticality: null,
    id_category: null,
    id_subcategory: null,
    category_name: null,
    subcategory_name: null,
  });

  const [error, setError] = useState<string>("");

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/ticket/category`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  // Fetch subcategories based on selected category
  const fetchSubCategories = async (categoryId: string) => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/ticket/subcategory/${categoryId}`
      );
      setSubCategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  // Handle category change
  const handleCategoryChange = (event: any) => {
    const selectedCategory = categories.find(
      (category) => category.category_name === event.target.value
    );
    if (selectedCategory) {
      setTicketInfo((prev) => ({
        ...prev,
        id_category: selectedCategory.id_category,
        category_name: selectedCategory.category_name,
        id_subcategory: 0, // Reset subcategory
        subcategory_name: "", // Reset subcategory name
      }));
      fetchSubCategories(selectedCategory.id_category);
    }
  };

  // Handle subcategory change
  const handleSubCategoryChange = (event: any) => {
    const selectedSubCategory = subCategories.find(
      (subCategory) => subCategory.subcategory_name === event.target.value
    );
    if (selectedSubCategory) {
      setTicketInfo((prev) => ({
        ...prev,
        id_subcategory: selectedSubCategory.id_subcategory,
        subcategory_name: selectedSubCategory.subcategory_name,
      }));
    }
  };

  // Management of the uploaded file via File Upload Drag Drop
  const handleFileUpload = (file: File | null) => {
    setTicketInfo((prevState) => ({
      ...prevState,
      file: file,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${backendUrl}/api/ticket`, ticketInfo);
      toast.success(translate("Ticket created successfully!"), {
        transition: Bounce,
      });
      refreshTickets();
      onHide();
    } catch (error) {
      console.error("Error creating ticket:", error);
      setError(translate("Failed to create ticket. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const priorityOptions = [
    { value: 1, label: translate("Urgent") },
    { value: 2, label: translate("VeryHigh") },
    { value: 3, label: translate("High") },
    { value: 4, label: translate("Medium") },
    { value: 5, label: translate("Low") },
  ];

  const criticalityOptions = [
    { value: 1, label: translate("Critical") },
    { value: 2, label: translate("Severe") },
    { value: 3, label: translate("Medium") },
    { value: 4, label: translate("Low") },
  ];


  const handleCreateTicketSubmit = (e:any) => {
   
  
    // Récupérer le fichier depuis un input (supposons que vous avez un input de type file)
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    const file = fileInput.files ? fileInput.files[0] : null;
  
    if (file) {
    createTicket(ticketInfo, file);  
    }
  };

  const createTicket = async (ticket: TicketInfo, file?: File) => {
    const isNameValid = ticket.title ? ticket.title.trim().length > 0 : false;
    const isDescriptionValid = ticket.description
      ? ticket.description.trim().length > 0
      : false;
  
    console.log("Processing ticket creation");
  
    if (!isNameValid || !isDescriptionValid) {
      const nameElement = document.getElementById("title");
      if (nameElement) {
        nameElement.style.borderColor = isNameValid ? "#ced4da" : "red";
      }
  
      const descriptionElement = document.getElementById("description");
      if (descriptionElement) {
        descriptionElement.style.borderColor = isDescriptionValid ? "#ced4da" : "red";
      }
  
      toast.warn("Please fill in all required fields", {
        position: "bottom-right",
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setButtonClicked(false);
  
      return;
    } else {
      try {
      
        const formData = new FormData();
        formData.append("description",  String(ticket.description));
        formData.append("title",  String(ticket.title));
        formData.append("id_user", String(userID));
        formData.append("priority", String(1)); 
        formData.append("status", String(1)); 
        formData.append("criticality", String(1)); 
        formData.append("id_category", String(ticket.id_category)); 
        formData.append("id_subcategory", String(ticket.id_subcategory)); 
  
    
        if (file) {
          formData.append("file", file);
        }
  
        const resCreate = await fetch(`${backendUrl}/api/ticket/create`, {
          method: "POST",
          body: formData, 
        });
  
        if (!resCreate.ok) {
          toast.warn("Can't create ticket", {
            position: "bottom-right",
            autoClose: 2400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          console.error("Error creating ticket");
          setButtonClicked(false);
          return;
        }
  
        toast.success("Ticket created successfully", {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
  
        setButtonClicked(false);
        refreshTickets();
      } catch (error) {
        toast.warn("Can't create ticket", {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setButtonClicked(false);
      }
    }
  };
  
  
  const resetTicketInfo = () => {
    setTicketInfo({
      id_ticket: null,
      agent: null,
      description: null,
      title: null,
      id_user: null,
      nom_user: null,
      creation_date: null,
      priority: null,
      status: null,
      criticality: null,
      id_category: null,
      id_subcategory: null,
      category_name: null,
      subcategory_name: null,
    });
  };

  return (  
    <Modal show={show}  onHide={() => {
      resetTicketInfo();
      onHide(); 
    }}size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <i
            className="las la-ticket-alt"
            data-rel="bootstrap-tooltip"
            title="Ticket"
            style={{ color: "orange" }}
          ></i>{" "}
          {translate("Create a new ticket")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTicketTitle" className="mb-3">
            <Form.Label>{translate("Ticket Title")}:</Form.Label>
            <Form.Control
              type="text"
              name="title"
              id="title"
              value={ticketInfo.title ?? ""}
              onChange={(e) =>
                setTicketInfo({ ...ticketInfo, title: e.target.value })
              }
              placeholder={translate("Enter the ticket title")}
              required
            />
          </Form.Group>
          <div className="row">
            <div className="col-12 col-md-6">
              <Form.Group controlId="formCategory" className="mb-3">
                <Form.Label>{translate("Category")}:</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  id="category"
                  value={ticketInfo.category_name ?? ""}
                  onChange={handleCategoryChange}
                  required
                >
                  <option value="">{translate("Select a category")}</option>
                  {categories.map((category) => (
                    <option
                      key={category.id_category}
                      value={category.category_name}
                    >
                      {translate(category.category_name)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>

            <div className="col-12 col-md-6">
              <Form.Group controlId="formSubCategory" className="mb-3">
                <Form.Label>{translate("Sub category")}:</Form.Label>
                <Form.Control
                  as="select"
                  id="id_subcategory"
                  name="id_subcategory"
                  value={ticketInfo.subcategory_name ?? ""}
                  onChange={handleSubCategoryChange}
                  disabled={!ticketInfo.id_category} // Disable if no category is selected
                  required
                >
                  <option value="">{translate("Select a sub category")}</option>
                  {subCategories.map((subCategory) => (
                    <option
                      key={subCategory.id_subcategory}
                      value={subCategory.subcategory_name}
                    >
                      {translate(subCategory.subcategory_name)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <Form.Group controlId="formPriority" className="mb-3">
                <Form.Label>{translate("Priority")}:</Form.Label>
                <Form.Control
                  as="select"
                  name="priority"
                  value={ticketInfo.priority ?? ""}
                  onChange={(e) =>
                    setTicketInfo((prev) => ({
                      ...prev,
                      priority: parseInt(e.target.value),
                    }))
                  }
                  required
                >
                  <option value="">{translate("Select priority")}</option>
                  {priorityOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {translate(option.label)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>

            <div className="col-12 col-md-6">
              <Form.Group controlId="formCriticality" className="mb-3">
                <Form.Label>{translate("Criticality")}:</Form.Label>
                <Form.Control
                  as="select"
                  name="criticality"
                  value={ticketInfo.criticality ?? ""}
                  onChange={(e) =>
                    setTicketInfo((prev) => ({
                      ...prev,
                      criticality: parseInt(e.target.value),
                    }))
                  }
                  required
                >
                  <option value="">{translate("Select criticality")}</option>
                  {criticalityOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {translate(option.label)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>

          <Form.Group controlId="formDescription" className="mb-3">
            <Form.Label>{translate("Description")}:</Form.Label>
            <ReactQuill
              value={ticketInfo.description ?? ""}
              onChange={(value) =>
                setTicketInfo((prev) => ({
                  ...prev,
                  description: value,
                }))
              }
              placeholder={translate("Write your description here...")}
              theme="snow"
              style={{ height: '130px' }} 
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }], // Titres
                  ['bold', 'italic', 'underline'], // Mise en forme
                  [{ list: 'ordered' }, { list: 'bullet' }], // Listes
                  ['link', 'image'], // Liens et images
                  ['clean'], // Nettoyer le formatage
                  [{ color: [] }, { background: [] }], // Couleurs de texte et d'arrière-plan
                  [{ align: [] }], // Alignement
                  ['blockquote'], // Citation
                  ['code-block'], // Bloc de code
                ],
              }}
            />
          </Form.Group>
          <Form.Group controlId="" className="mb-3" style={{marginTop:"60px"}}>
          <FileUploadDragDrop
            onFileSelect={handleFileUpload}
            maxSize={2 * 1024 * 1024} // Limit à 2MB
          />
           </Form.Group>
        </Form>
      </Modal.Body>
      <div className="modal-footer">
        <button
          onClick={() => {
            onHide(); // Make sure you have a state to control the visibility of the modal
            resetTicketInfo()
          }}
          type="button"
          className="btn btn-default"
          data-dismiss="modal"
        >
          {translate("Cancel")}
        </button>
        {error && !loading && (
          <div style={{ color: "red" }} className="mb-3">
            {error}
          </div>
        )}

        {loading ? (
          <div className="d-flex justify-content-center mb-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{translate("Loading")}...</span>
            </Spinner>
          </div>
        ) : (
          <Button
            onClick={() => {
              setButtonClicked(true);
              console.log(id_ticket);
              id_ticket === 0
                ? handleCreateTicketSubmit(ticketInfo)
                : handleCreateTicketSubmit(ticketInfo);
            }}
            variant="primary"
            type="submit"
            disabled={buttonClicked}
          >
            {id_ticket === 0
              ? translate("Create ticket")
              : translate("update ticket")}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CreateTicketModal;
